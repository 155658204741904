import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { useGoogleLogin } from 'react-google-login'; // https://www.npmjs.com/package/react-google-login
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'; // https://www.npmjs.com/package/react-facebook-login
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from '../../apollo/lib';

import { GOOGLE_OAUTH_CLIENT_ID, FACEBOOK_OAUTH_APP_ID, THIRD_PARTY_AUTH_PASSWORD_SALT } from '../../config';
import useLanguage from '../_hooks/useLanguage';
import useAuth from '../_hooks/useAuth';
import { gqlValidation, setContext } from '../../helpers/gql';

const ThirdpartyAuth = ({ action, onError, onSuccess }) => {
	const { t } = useLanguage();
	const { login } = useAuth();

	const [settings] = useState({
		directAuth: false, // true = after user grand permission, request to server is triggerd to login or register, false = show & prefill register (manual) form (without password field, which is generated)
		onUserNotExistDoRegister: false // true = on login, if user does not exist, run register & then login, false = if user not exist, show error message
	});
	const { directAuth, onUserNotExistDoRegister } = settings;

	const [authAction, setAuthAction] = useState(action);

	const [passwordSalt] = useState(THIRD_PARTY_AUTH_PASSWORD_SALT);

	const [isEnabled, setIsEnabled] = useState({
		google: true,
		facebook: true
	});

	const [lastProvider, setLastProvider] = useState(null);
	const [lastProviderData, setLastProviderData] = useState({
		google: {},
		facebook: {},
		cnt: 0 // to run useEffect, if data didnt changed (and tried again for ex.)
	});
	const [triedOpposite, setTriedOpposite] = useState(false);

	const cyrb53 = (str, seed = 0) => {
		let h1 = 0xdeadbeef ^ seed;
		let h2 = 0x41c6ce57 ^ seed;

		for (let i = 0, ch; i < str.length; i++) {
			ch = str.charCodeAt(i);
			h1 = Math.imul(h1 ^ ch, 2654435761);
			h2 = Math.imul(h2 ^ ch, 1597334677);
		}

		h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
		h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

		return `a${4294967296 * (2097151 & h2) + (h1 >>> 0)}9`; // geslo mora vsebivati vsaj eno črno in številko
	};

	// GOOGLE

	const { signIn } = useGoogleLogin({
		clientId: GOOGLE_OAUTH_CLIENT_ID,
		isSignedIn: false, // do not load user data on load, if user is already with permissions
		autoLoad: false,
		onSuccess: res => onTPSuccess('google', res),
		onFailure: res => onTPFailure('google', res),
		accessType: 'online'
		// responseType: 'code',
		// prompt: 'consent'
	});

	// COMMON

	const getGqlSchema = () => {
		switch (authAction) {
		case 'register': 								return require('../../requests/mutation/auth/register.js').default.gql.schema;
		case 'login': 									return require('../../requests/mutation/auth/login.js').default.gql.schema;
		default: return null;
		}
	};

	const [gqlMutation, { data, error }] = useMutation(getGqlSchema(authAction), { errorPolicy: 'all' });

	const onTPSuccess = (provider, d) => {
		setLastProvider(provider);
		setLastProviderData({ ...lastProviderData, [provider]: d, cnt: lastProviderData.cnt + 1 });
	};

	const onTPFailure = (provider, e) => {
		// if (process.env.NODE_ENV === 'development') {
			console.log('Third-party auth error', e);
		// }

		setLastProvider(provider);

		if (provider === 'google') {
			if (e.error === 'idpiframe_initialization_failed') { // error on init, hide button (https://developers.google.com/identity/sign-in/web/reference)
				setIsEnabled({ ...isEnabled, google: false });
			} else {
				onError({
					provider: e.details
				}, {});
			}
		}
	};

	useEffect(() => {
		if (lastProvider !== null) {
			let user = {};

			switch (lastProvider) {
			case 'google': {
				const { googleId, givenName, familyName, email } = lastProviderData.google.profileObj;
				// const email = 'klemen@potres.si';

				// console.log('GOOGLE DATA', lastProviderData.google.profileObj);

				user = authAction === 'login'
					? { // Login
						username: email || null, // email as username
						password: googleId ? cyrb53(`${passwordSalt}${googleId}`) : null, // password: letters + numbers validation!
						long_period: true
					}
					: { // Register
						username: email || null, // email as username
						password: googleId ? cyrb53(`${passwordSalt}${googleId}`) : null, // password: letters + numbers validation!
						givenname: givenName || null,
						surname: familyName || null,
						email: email || null,
						agreement_terms: true,
						oauth_id: googleId || null,
						oauth_provider: lastProvider || null
					};
				break;
			}

			case 'facebook': {
				const { userID, name, email } = lastProviderData.facebook;
				// const email = 'klemen@potres.si';
				const nameParts = name ? name.split(' ') : [];

				// console.log('FACEBOOK DATA', lastProviderData.facebook, `${passwordSalt}${userID}`);

				user = authAction === 'login'
					? { // Login
						username: email || null, // name as username
						password: userID ? cyrb53(`${passwordSalt}${userID}`) : null, // password: letters + numbers validation!
						long_period: true
					}
					: { // Register
						username: email || null, // name as username
						password: userID ? cyrb53(`${passwordSalt}${userID}`) : null, // password: letters + numbers validation!
						givenname: nameParts[0] || null,
						surname: nameParts[1] || null,
						email: email || null,
						agreement_terms: true,
						oauth_id: lastProviderData.facebook.userID || null,
						oauth_provider: lastProvider || null
					};
				break;
			}

			default: break;
			}

			// console.log('USER', user);

			switch (authAction) {
			case 'register':
				if (directAuth === true) {
					// Validate, if all neccesary data are presented
					if (user.username && user.password && user.givenname && user.surname && user.email) {
						gqlMutation({
							variables: user,
							context: setContext(`auth>${authAction}`)
						});
					}
				} else if (onSuccess) {
					onSuccess({ authAction, action: 'prefill', ...user });
				}
				break;

			default:
			case 'login':
				if (user.username && user.password) {
					gqlMutation({
						variables: user,
						context: setContext(`auth>${authAction}`)
					});
				}
				break;
			}
		}
	}, [lastProviderData, authAction]);

	useEffect(() => {
		if (error) {
			const lastErrors = gqlValidation(error);
			onError(lastErrors, error);

			if (onUserNotExistDoRegister === true) {
				// If login and account not exist yet, switch to register and create!
				// If register, try to login with same provider
				if (triedOpposite === false) {
					if (lastErrors.username) { // if something with username ... try opposite action.
						setTriedOpposite(true);
						onError({});
						setAuthAction(authAction === 'login' ? 'register' : 'login');
					}
				} else {
					setTriedOpposite(false);
				}
			}
		} else if (data && data.request && data.request.access_token) {
			if (onSuccess) onSuccess({ authAction, ...data.request });
			else {
				login(data.request.access_token);
				Router.push('/');
			}
		}

		return true;
	}, [data, error]);

	return (
		<>
			{isEnabled.facebook && (
				<FacebookLogin
					appId={FACEBOOK_OAUTH_APP_ID}
					autoLoad={false}
					fields="name,email"
					scope="public_profile,email"
					callback={res => onTPSuccess('facebook', res)}
					render={renderProps => (
						<button type="button" className="btn facebook i p100" onClick={renderProps.onClick}><i className="icon facebook white" />{t('btnLoginFacebook')}</button>
					)}
				/>
			)}
			{isEnabled.google && <button type="button" className="btn outline default i p100" onClick={() => signIn()}><i className="icon google" />{t('btnLoginGoogle')}</button>}
		</>
	);
};

ThirdpartyAuth.defaultProps = {
	onSuccess: null
};

ThirdpartyAuth.propTypes = {
	onError: PropTypes.func.isRequired,
	onSuccess: PropTypes.func,
	action: PropTypes.string.isRequired
};

export default withApollo({ ssr: true })(ThirdpartyAuth);
